// components/ui/landing/Navigation.tsx
'use client';

import React, {useEffect, useRef, useState} from 'react';
import Link from 'next/link';
import {usePathname} from 'next/navigation';
import {ChevronDown, ChevronUp} from "flowbite-react-icons/outline";
import useSWR from 'swr';
import {CategorySummary} from '@/app/api/types';
import LogoShort from "@/components/ui/LogoShort";
import LogoWortmarke from "@/components/ui/LogoWortmarke";

type NavigationProps = {
  className?: string;
};

const Navigation: React.FC<NavigationProps> = ({className = ''}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const pathname = usePathname();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const {data: categoriesData, error, isLoading} = useSWR<CategorySummary[]>(
    '/api/categories',
    async (url) => {
      const res = await fetch(url);
      if (!res.ok) throw new Error('Failed to fetch categories');
      return res.json();
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 3600000 // 1 hour
    }
  );

  // Ensure categories is always a valid array, even during loading or on error
  const categories = Array.isArray(categoriesData) ? categoriesData : [];

  // Close mobile menu when route changes
  useEffect(() => {
    setIsMobileMenuOpen(false);
    setIsCategoryDropdownOpen(false);
  }, [pathname]);

  // Handle click outside for desktop dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isCategoryDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)) {
        setIsCategoryDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCategoryDropdownOpen]);

  // Handle escape key to close menus
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsMobileMenuOpen(false);
        setIsCategoryDropdownOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // Prevent scrolling when mobile menu is open
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobileMenuOpen]);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const toggleCategoryDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
  };

  // Simple loading indicator for the categories dropdown
  const categoryDisplay = isLoading ? (
    <div className="animate-pulse h-6 bg-gray-200 rounded w-24"></div>
  ) : (
    <>
      <span>Kategorien</span>
      {isCategoryDropdownOpen ? (
        <ChevronUp className="ml-1 w-4 h-4"/>
      ) : (
        <ChevronDown className="ml-1 w-4 h-4"/>
      )}
    </>
  );

  // Helper function to safely generate category links
  const getCategoryLink = (category: CategorySummary | undefined) => {
    if (!category || !category.id || typeof category.id !== 'string' || category.id.trim() === '') {
      return '/categories'; // Default fallback
    }
    return `/category/${category.id}`;
  };

  // Helper function to safely get category name
  const getCategoryName = (category: CategorySummary | undefined) => {
    return category?.name || 'Kategorie';
  };

  // Only render categories that have valid IDs
  const validCategories = categories.filter(
    category => category && typeof category.id === 'string' && category.id.trim() !== ''
  );

  return (
    <nav className={`${className}`}>
      {/* Desktop Navigation */}
      <div className="hidden lg:flex items-center space-x-6">
        {/* Categories dropdown */}
        <div className="relative" ref={dropdownRef}>
          <button
            className="flex items-center text-gray-700 hover:text-primary px-2 py-1 rounded-md transition duration-150 ease-in-out"
            onClick={toggleCategoryDropdown}
            aria-expanded={isCategoryDropdownOpen}
            aria-haspopup="true"
            type="button"
          >
            {categoryDisplay}
          </button>

          {isCategoryDropdownOpen && (
            <div
              className="absolute z-10 left-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
            >
              <div className="py-1">
                {isLoading ? (
                  // Loading state - show skeleton loaders
                  Array(3).fill(0).map((_, index) => (
                    <div key={`loading-${index}`} className="animate-pulse h-10 bg-gray-100 m-2 rounded"></div>
                  ))
                ) : validCategories.length > 0 ? (
                  <>
                    {validCategories.slice(0, 6).map((category) => (
                      <Link
                        key={category.id}
                        href={getCategoryLink(category)}
                        className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 hover:text-primary"
                        role="menuitem"
                        onClick={() => setIsCategoryDropdownOpen(false)}
                      >
                        {getCategoryName(category)}
                      </Link>
                    ))}
                    {validCategories.length > 6 && (
                      <Link
                        href="/categories"
                        className="block px-4 py-3 text-sm font-medium text-primary hover:bg-gray-100"
                        role="menuitem"
                        onClick={() => setIsCategoryDropdownOpen(false)}
                      >
                        Mehr Kategorien anzeigen
                      </Link>
                    )}
                  </>
                ) : (
                  <div className="px-4 py-3 text-sm text-gray-500">Keine Kategorien verfügbar</div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Direct links */}
        <Link
          href="/budget-offers"
          className="text-gray-700 hover:text-primary px-2 py-1 rounded-md transition duration-150 ease-in-out"
        >
          Kennenlern-Angebote
        </Link>
        <Link
          href="/products"
          className="text-gray-700 hover:text-primary px-2 py-1 rounded-md transition duration-150 ease-in-out"
        >
          Beratungen
        </Link>

        <Link
          href="/guides"
          className="text-gray-700 hover:text-primary px-2 py-1 rounded-md transition duration-150 ease-in-out"
        >
          Guides
        </Link>
      </div>

      {/* Mobile Hamburger Button */}
      <button
        className="lg:hidden p-2 rounded-md text-gray-700 hover:text-primary hover:bg-gray-100 focus:outline-none"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        aria-expanded={isMobileMenuOpen}
        aria-label="Hauptmenü"
        type="button"
      >
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d={isMobileMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
          />
        </svg>
      </button>

      {/* Mobile Slide-in Sidebar */}
      <div
        className={`fixed inset-0 bg-gray-600 bg-opacity-75 z-40 transition-opacity duration-300 ${
          isMobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={closeMobileMenu}
        aria-hidden="true"
      />

      <div
        ref={sidebarRef}
        className={`fixed inset-y-0 left-0 flex flex-col w-72 max-w-sm bg-white shadow-xl z-50 transform transition-transform duration-300 ease-in-out ${
          isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* Sidebar Header with Close button */}
        <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
          {/* Mobile: Show LogoShort */}
          <div className="md:hidden">
            <LogoShort size={40}/>
          </div>

          {/* Desktop: Show LogoWortmarke */}
          <div className="hidden md:block">
            <LogoWortmarke size={40}/>
          </div>
          <button
            className="p-2 rounded-md text-gray-400 hover:text-primary hover:bg-gray-100"
            onClick={closeMobileMenu}
            aria-label="Menü schließen"
            type="button"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Sidebar Content */}
        <div className="flex-1 overflow-y-auto">
          <div className="px-4 py-4">
            {/* Make header a link to all categories */}
            <Link
              href="/categories"
              className="inline-flex items-center text-md font-medium text-primary mb-2 hover:underline"
              onClick={closeMobileMenu}
            >
              <h3>Kategorien</h3>
            </Link>

            <div className="space-y-1">
              {isLoading ? (
                // Loading state for categories in mobile menu
                Array(3).fill(0).map((_, index) => (
                  <div key={`mobile-loading-${index}`} className="animate-pulse h-12 bg-gray-200 rounded my-2"></div>
                ))
              ) : validCategories.length > 0 ? (
                <>
                  {validCategories.slice(0, 6).map((category) => (
                    <Link
                      key={category.id}
                      href={getCategoryLink(category)}
                      className="block px-2 py-3 text-base font-medium text-gray-700 hover:text-primary hover:bg-gray-50 rounded-md"
                      onClick={closeMobileMenu}
                    >
                      {getCategoryName(category)}
                    </Link>
                  ))}
                  {validCategories.length > 6 && (
                    <Link
                      href="/categories"
                      className="block px-2 py-3 text-base font-medium text-primary hover:bg-gray-50 rounded-md"
                      onClick={closeMobileMenu}
                    >
                      Mehr Kategorien anzeigen →
                    </Link>
                  )}
                </>
              ) : (
                <div className="px-2 py-3 text-base text-gray-500">Keine Kategorien verfügbar</div>
              )}
            </div>

            <div className="border-t border-gray-200 my-4"></div>

            <h3 className="text-md font-medium text-primary mb-2">Seiten</h3>
            <div className="space-y-1">
              <div className="space-y-1">
                <Link
                  href="/budget-offers"
                  aria-label="Kennenlern-Angebote – Lerne unsere Guides in einem günstigen Erstgespräch kennen. "
                  title="Kennenlern-Angebote – Lerne unsere Guides in einem günstigen Erstgespräch kennen."
                  className="block px-2 py-3 text-base font-medium text-gray-700 hover:text-primary hover:bg-gray-50 rounded-md"
                  onClick={closeMobileMenu}
                >
                  Kennenlern-Angebote
                </Link>
                <Link
                  href="/products"
                  aria-label="Beratungen – alle Angebote nach Kategorien"
                  title="Alle Beratungsangebote sortiert nach Kategorien"
                  className="block px-2 py-3 text-base font-medium text-gray-700 hover:text-primary hover:bg-gray-50 rounded-md"
                  onClick={closeMobileMenu}
                >
                  Beratungen
                </Link>
                <Link
                  href="/guides"
                  aria-label="Guides – Übersicht aller BeraterInnen"
                  title="Übersicht aller BeraterInnen"
                  className="block px-2 py-3 text-base font-medium text-gray-700 hover:text-primary hover:bg-gray-50 rounded-md"
                  onClick={closeMobileMenu}
                >
                  Guides
                </Link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;