// components/ui/CookieSettingsButton.tsx
'use client'

import { useConsent } from '@/app/contexts/ConsentContext';

export default function CookieSettingsButton() {
  const { openConsentModal } = useConsent();

  return (
    <button
      onClick={openConsentModal}
      className="hover:text-gray-900 text-left"
    >
      Cookie-Einstellungen
    </button>
  );
}